import React from "react";
import styled from "styled-components";
import { gtmDataLayerPush } from "../../utils/tracking";

export default function ExternalLink({ href, name, children, ...other }) {
  return (
    <LinkWrapper
      href={href}
      {...other}
      rel="noreferrer noopener"
      target="_blank"
      onClick={() => gtmDataLayerPush(name, "footer-link")}
    >
      {children}
    </LinkWrapper>
  );
}

const LinkWrapper = styled.a`
  color: black;
  padding: 20px;
`;
