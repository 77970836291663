/* eslint-disable no-underscore-dangle */
import React from "react";
import styled from "styled-components";
import { HEADER_LEVEL } from "../../utils/commonConst";
import { FontFamilies, Colors, Breakpoints } from "../../styles/styleConstants";

export const headerStyle = {
  [HEADER_LEVEL.LEVEL_1]: `
    font-family: ${FontFamilies.title};
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 1.2;
    color: ${Colors.almostBlack};

    @media only screen and (min-width: ${Breakpoints.md}) {
      font-size: 44px;
    }
`,
  [HEADER_LEVEL.LEVEL_1_1]: `
    font-family: ${FontFamilies.base};
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2;
    color: ${Colors.almostBlack};

    @media only screen and (min-width: ${Breakpoints.md}) {
      font-size: 44px;
    }
`,
  [HEADER_LEVEL.LEVEL_2]: `
    font-family: ${FontFamilies.title};
    font-style: normal;
    font-weight: bold;
    line-height: 1.2;
    color: ${Colors.almostBlack};

    @media only screen and (min-width: ${Breakpoints.md}) {
      font-size: 32px;
    }
`,
  [HEADER_LEVEL.LEVEL_3]: `
    font-family: ${FontFamilies.base};
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.2;
    color: ${Colors.almostBlack};
    
    @media only screen and (min-width: ${Breakpoints.md}) {
      font-size: 28px;
    }
`,
  [HEADER_LEVEL.LEVEL_4]: `
    font-family: ${FontFamilies.title};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: ${Colors.almostBlack};
    
    @media only screen and (min-width: ${Breakpoints.md}) {
      font-size: 24px;
    }
`,
  [HEADER_LEVEL.LEVEL_4_1]: `
    font-family: ${FontFamilies.base};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: ${Colors.almostBlack};

    @media only screen and (min-width: ${Breakpoints.md}) {
      font-size: 20px;
    }
`,
  // Paragraph headline large;
  [HEADER_LEVEL.LEVEL_5]: `
    font-family: ${FontFamilies.base};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    color: ${Colors.almostBlack};
    
    @media only screen and (min-width: ${Breakpoints.md}) {
      font-size: 20px;
    }
    `,
  [HEADER_LEVEL.LEVEL_6]: `
    font-family: ${FontFamilies.base};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: ${Colors.almostBlack};
    `,
  [HEADER_LEVEL.LEVEL_6_1]: `
  font-family: ${FontFamilies.base};
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.almostBlack};
  `,
  [HEADER_LEVEL.LEVEL_7]: `
    font-family: ${FontFamilies.base};
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.5;
    color: ${Colors.almostBlack};
    `,
  [HEADER_LEVEL.BODY_COPY]: `
    font-family: ${FontFamilies.base};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${Colors.almostBlack};
    `,
};

function HeaderText(props) {
  const {
    level = HEADER_LEVEL.LEVEL_3,
    children,
    style,
    inline = false,
  } = props || {};
  return (
    <HeaderTextWrapper style={style} inline={inline}>
      <Text headerLevel={level} color={style?.color} inline={inline}>
        {children}
      </Text>
    </HeaderTextWrapper>
  );
}

export default HeaderText;

const HeaderTextWrapper = styled.div`
  ${(props) =>
    props.style && props.style.margin ? `${props.style.margin};` : ""}
  ${(props) => (props.inline ? `display: inline;` : "")}
`;

const Text = styled.div`
  color: ${Colors.almostBlack};
  ${(props) =>
    props.headerLevel
      ? headerStyle[props.headerLevel]
      : headerStyle[HEADER_LEVEL.LEVEL_4]}
  ${(props) => props?.color && `color: ${props?.color};`}
  ${(props) => (props.inline ? `display: inline;` : "")}
`;
