export const Breakpoints = {
  xs: "0",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
};

export const BreakpointsDown = {
  sm: "575px",
  md: "767px",
  lg: "991px",
  xl: "1199px",
};

export const Spacing = {
  xs: "8px",
  sm: "12px",
  md: "16px",
  lg: "24px",
  xl: "32px",
  xxl: "40px",
  xxxl: "48px",
  xxxxl: "72px",
  xxxxxl: "80px",
};

export const Colors = {
  primary: "#3278f9",
  tertiary: "#051242",
  gray500: "#989ba5",
  black: "#000",
  almostBlack: "#212529",
  neutralDark: "#58595B",
  neutralMedium: "#D1D3D4",
  neutralMedLight: "#F3F4F4",
  neutralMedDark: "#909294",
  neutralLight: "#F7F7F7",
  white: "#FFFFFF",
  errorRed: "#FF0100",
  errorRed20: "#FFCCCC",
  blue: "#3C5FFF",
  blue30: "#C5CFFF",
  blue60: "#EBEFFF",
  blueUnamed1: "#307cf9",
  teal: "#00F4DE",
  secondary: "#00f4de",
  teal30: "#B3FCF6",
  teal10: "#E5FEFC",
  altOrange: "#FBBB8D",
  orange40: "#FBBB8D",
  gray700: "#564f62", // not in design foundation.
  green: "#168751",
  red30: "#CE3130",
  cyanShade: "#00d8c4",
  transparent: "transparent",
  coral: "#FB6F8C",
  sunShine: "#FFDB3C",
  nerdwalletBlue: "#005FB9",
  nerdwalletDarkBlue: "#02143A",
  nerdwalletLightGray: "#D8D9DA",
  nerdwalletLightBlue: "#5994E0",
  nerdwalletBackgroundBlue: "#DEEFFF",
  nerdwalletBorderBlue: "#4B99E6",
  nerdwalletPurple: "#4065F6",
  nerdWalletBoxShadow: "#DFDFDF",
  nerdWalletFreeBGYellow: "#FFF37B",
};

export const BorderRaidii = {
  lg: "1rem",
  xl: "2.5rem",
};

export const FontSizes = {
  xxs: "10px",
  xs: "12px",
  sm: "14px",
  md: "16px",
  default: "16px",
  lg: "20px",
  xl: "28px",
  xxl: "36px",
  xxxl: "44px",
};

export const FontWeights = {
  extraLight: "200",
  light: "300",
  normal: "400",
  bold: "600",
  bolder: "700",
  extraBold: "800",
};

export const FontFamilies = {
  base: "'BR Sonoma Regular', Montserrat, 'Helvetica Neue', Arial, Helvetica, sans-serif",
  title:
    "'BR Sonoma SemiBold', Montserrat, 'Helvetica Neue', Arial, Helvetica, sans-serif",
};

export const ResponsiveCarousel = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};
