import React from "react";
import styled from "styled-components";
import { FontFamilies } from "../../styles/styleConstants";

export default function SemiBoldTitle(props) {
  const { children, style, color } = props ?? {};
  return (
    <SemiBold style={style} color={color}>
      {children}
    </SemiBold>
  );
}

const SemiBold = styled.div`
  font-family: ${FontFamilies.title};
  ${(props) => (props?.color ? `color: ${props.color};` : "")}
  ${(props) =>
    props?.style?.fontWeight ? `font-weight: ${props.style.fontWeight};` : ""}
  font-weight: 400;
`;
