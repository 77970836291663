import React from "react";
import styled from "styled-components";
import {
  Colors,
  FontFamilies,
  FontSizes,
  FontWeights,
} from "../../styles/styleConstants";
import { BODY_TEXT_TYPE } from "../../utils/commonConst";

function BodyText(props) {
  const {
    children,
    style = undefined,
    type = BODY_TEXT_TYPE.BODY_COPY_NORMAL,
    color = Colors.almostBlack,
    className,
  } = props || {};
  return (
    <BodyTextWrapper style={style} className={className}>
      <Text type={type} color={color}>
        {children}
      </Text>
    </BodyTextWrapper>
  );
}

export default BodyText;

const BodyTextWrapper = styled.div`
  ${(props) =>
    props.style && props.style.margin ? `${props.style.margin};` : ""}
`;

const Text = styled.div`
  ${(props) => {
    switch (props.type) {
      case BODY_TEXT_TYPE.BODY_COPY_SMALL: {
        return `
          font-family: ${FontFamilies.base};
          font-style: normal;
          font-weight: ${FontWeights.normal}};
          font-size: ${FontSizes.sm};
          line-height: 24px;
          color: ${props?.color};
        `;
      }
      case BODY_TEXT_TYPE.BODY_COPY_TITLE: {
        return `
          font-family: ${FontFamilies.base};
          font-style: normal;
          font-weight: ${FontWeights.bold}};
          font-size: ${FontSizes.xl};
          line-height: 34px;
          color: ${props?.color};
        `;
      }
      case BODY_TEXT_TYPE.BODY_TEXT_SMALL: {
        return `
          font-family: ${FontFamilies.base};
          font-style: normal;
          font-weight: ${FontWeights.bold}};
          font-size: ${FontSizes.md};
          line-height: 24px;
          color: ${props?.color};
        `;
      }
      case BODY_TEXT_TYPE.BODY_COPY_LARGE: {
        return `
          font-family: ${FontFamilies.base};
          font-style: normal;
          font-weight: ${FontWeights.normal}};
          font-size: ${FontSizes.lg};
          line-height: 30px;
          color: ${props?.color};
        `;
      }
      default: {
        return `
          font-family: ${FontFamilies.base};
          font-style: normal;
          font-weight: ${FontWeights.normal}};
          font-size: ${FontSizes.default};
          line-height: 24px;
          color: ${props?.color};
      `;
      }
    }
  }}}
`;
