import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Image from "./Image";
import ExternalLink from "./externalLink";
import SocialMediaIcons from "./socialMediaIcons";
import { Breakpoints, BreakpointsDown } from "../../styles/styleConstants";
import { FORM_ADV, PRIVACY_URL, TERMS_URL } from "../../utils/commonConst";
import SemiBoldTitle from "./SemiBoldTitle";
export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <Container className="footerContainer" id="footer">
      <Row>
        <Col>
          <FooterWrapper>
            <Image
              className="footerLogo"
              src="common/uprise-logo.png"
              alt="Uprise"
              style={{
                width: "160px",
                height: "40px",
                display: "inline-block",
              }}
              imgStyle={{ objectFit: "contain" }}
            />
            <FooterLinks>
              <ExternalLink
                href="https://wellfound.com/company/uprisemoney"
                name="career"
              >
                <SemiBoldTitle>Careers</SemiBoldTitle>
              </ExternalLink>
              <ExternalLink href={TERMS_URL} name="terms">
                <SemiBoldTitle>Terms of service</SemiBoldTitle>
              </ExternalLink>
              <ExternalLink href={PRIVACY_URL} name="privacy">
                <SemiBoldTitle>Privacy policy</SemiBoldTitle>
              </ExternalLink>
              <ExternalLink href={FORM_ADV} name="adv">
                <SemiBoldTitle>Form ADV</SemiBoldTitle>
              </ExternalLink>
            </FooterLinks>
            <SocialMediaIconsRow>
              <SocialMediaIcons />
            </SocialMediaIconsRow>
            <LegalDisclaimerWrapper>
              <div>
                <div>
                  Financial planning, other than advice regarding investing in
                  securities, and financial planning tools are offered by
                  Ontrail, Inc., doing business as “Uprise”, a corporation
                  registered in Delaware, United States. Investment advisory and
                  financial planning including advice regarding securities are
                  provided by Uprise Advisers LLC (“Uprise Advisers”), a
                  registered investment adviser and wholly owned subsidiary of
                  Ontrail, Inc. Uprise Advisers may only transact business in
                  those states in which it is registered, or qualifies for an
                  exemption or exclusion from registration requirements.
                </div>
                <br />
                <div>
                  Uprise’s website is limited to the dissemination of general
                  information pertaining to its financial planning and advisory
                  services (which expressly excludes advice regarding
                  securities). This website and information provided herein are
                  not intended to provide investment, tax, or legal advice. All
                  testimonials on this website relate to Uprise, not Uprise
                  Advisers or its services.
                </div>
              </div>
              <br />
              <div>
                Ontrail, Inc. dba Uprise © {currentYear}. Ontrail, Inc. is
                registered in Delaware, United States.
              </div>
            </LegalDisclaimerWrapper>
          </FooterWrapper>
        </Col>
      </Row>
    </Container>
  );
}

const FooterWrapper = styled.div`
  text-align: center;
  .footerLogo {
    filter: brightness(0);
  }
  padding-top: 2em;
`;

const LegalDisclaimerWrapper = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: normal;
`;

const FooterLinks = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px;

  @media (max-width: ${BreakpointsDown.sm}) {
    flex-direction: column;
    > a {
      padding-top: 10px;
      padding-bottom: 10px;
      display: inline-block;
    }
  }
`;

const SocialMediaIconsRow = styled.div`
  padding: 0 0 1.5em;
`;
