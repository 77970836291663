import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Navbar, Nav, Container } from "react-bootstrap";
import Image from "./Image";
import { Breakpoints, Colors, Spacing } from "../../styles/styleConstants";
import { gtmDataLayerPush } from "../../utils/tracking";
import {
  encodeUrl,
  getAppUrlByModule,
  isEmpty,
  isNonEmpty,
  navigateTo,
} from "../../utils/commonUtil";
import {
  BODY_TEXT_TYPE,
  HEADER_LEVEL,
  MODULE_NAME,
  OTHER_APP_PAGES,
  PAGE_PATH,
} from "../../utils/commonConst";
import Menu from "./Menu";
import HeaderText from "./HeaderText";
import BodyText from "./BodyText";
import CtaButton from "./CtaButton";
import SemiBoldTitle from "./SemiBoldTitle";

const MENU_LABELS = {
  PARTNERSHIPS: "For partners",
  INDIVIDUALS: "For individuals",
  ABOUT_US: "About us",
};
const HOME_PAGE_MENU = [
  // ...CHAT_MENU_META_DATA,

  {
    icon: {
      src: "v2.0/personalized-money-plan.png",
      width: "52px",
      height: "52px",
    },
    label: "How it works",
    labelHelperText: "",
    navigationLink: PAGE_PATH.HOW_IT_WORKS,
  },
  {
    icon: {
      src: "v2.0/pricing-single.png",
      width: "52px",
      height: "52px",
    },
    label: "Core",
    labelHelperText: "All the money hacks, personalized for you.",
    navigationLink: PAGE_PATH[MODULE_NAME.CORE],
  },
  {
    icon: {
      src: "v2.0/pricing-allaccess.png",
      width: "52px",
      height: "52px",
    },
    label: "All-Access",
    labelHelperText: "All the money hacks, plus specific investment advice.",
    navigationLink: PAGE_PATH[MODULE_NAME.ALL_ACCESS],
  },
  // {
  //   icon: {
  //     src: "v2.0/menu-moneyalloc.png",
  //     width: "52px",
  //     height: "52px",
  //   },
  //   label: "Money allocation",
  //   labelHelperText: "Make each dollar work harder for you.",
  //   navigationLink: PAGE_PATH[MODULE_NAME.MONEY_ALLOCATION],
  // },
  // {
  //   icon: {
  //     src: "v2.0/menu-retirement.png",
  //     width: "52px",
  //     height: "52px",
  //   },
  //   label: "Retirement",
  //   labelHelperText: "Get to financial independence faster.",
  //   navigationLink: PAGE_PATH[MODULE_NAME.RETIREMENT],
  // },
  // {
  //   icon: {
  //     src: "v2.0/menu-equity.png",
  //     width: "52px",
  //     height: "52px",
  //   },
  //   label: "Company equity",
  //   labelHelperText: "Know exactly what to do with your stock options.",
  //   navigationLink: PAGE_PATH[MODULE_NAME.COMPANY_EQUITY],
  // },
  // {
  //   icon: {
  //     src: "v2.0/menu-portfolio.png",
  //     width: "52px",
  //     height: "52px",
  //   },
  //   label: "Portfolio advice",
  //   labelHelperText: "Build your investing strategy with a pro. ",
  //   navigationLink: PAGE_PATH[MODULE_NAME.PORTFOLIO_ADVICE],
  // },
  // {
  //   icon: {
  //     src: "v2.0/pricing-allaccess.png",
  //     width: "52px",
  //     height: "52px",
  //   },
  //   label: "All-Access",
  //   labelHelperText: "All the money hacks, plus ask us anything all year long.",
  //   navigationLink: PAGE_PATH[MODULE_NAME.ALL_ACCESS],
  // },
];

export default function Header(props) {
  const { source, hideMenus = false } = props ?? {};
  const [paramString, setParamString] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    setParamString(window.location.search?.replaceAll("/", ""));
  }, []);
  return (
    <HeaderWrapper>
      <NavBarRootWrapper>
        <Navbar.Brand
          href={`/${isEmpty(source) ? "" : `${source}/`}${paramString}`}
        >
          <Image
            src="common/uprise-logo.png"
            alt="Uprise"
            className="HeaderDesktopLogo"
            imgStyle={{ objectFit: "contain" }}
          />
          <Image
            src="common/uprise-logo-icon.png"
            alt="Uprise"
            className="HeaderMobileLogo"
            imgStyle={{ objectFit: "contain" }}
          />
        </Navbar.Brand>
        <MobileMenu onClick={() => setToggle(!toggle)}>
          {toggle ? (
            <Image
              src="home/close-icon.png"
              alt="Close Icon"
              className="closeImage"
              imgStyle={{ objectFit: "contain" }}
            />
          ) : (
            <Image
              src="home/menu-nav-ic.png"
              alt="Menu Bar Icon"
              className="menuBarImage"
              imgStyle={{ objectFit: "contain" }}
            />
          )}
        </MobileMenu>
        {hideMenus === false && (
          <NavLinkDesktopOnlyWrapper>
            <Navbar id="basic-navbar-nav">
              {/* <NavWrapper> */}
              <Nav className="ml-auto">
                {/* <NavLinkWrapper>
              <Nav.Link
                href={`/${paramString}#howitworks`}
                onClick={() =>
                  gtmDataLayerPush("howitworks-link-click", "header")
                }
              >
                How it works
              </Nav.Link>
            </NavLinkWrapper> */}
                {/* <NavLinkWrapper>
              <Nav.Link
                href={`/${paramString}#faq`}
                onClick={() => gtmDataLayerPush("faq-link-click", "header")}
              >
                FAQ
              </Nav.Link>
            </NavLinkWrapper> */}
                {/* <NavLinkDesktopOnlyWrapper> */}
                <Nav.Link
                  // href={`/about-us/${paramString}`}
                  href={encodeUrl(PAGE_PATH.PARTNER)}
                  onClick={() =>
                    gtmDataLayerPush("partnership-link-click", "header")
                  }
                >
                  <SemiBoldTitle>{MENU_LABELS.PARTNERSHIPS}</SemiBoldTitle>
                </Nav.Link>
                <NavLinkMenuWrapper>
                  <Nav.Link
                    // href={`/about-us/${paramString}`}
                    // href={encodeUrl(`/about-us/`)}
                    onClick={() => {
                      setShowMenu(true);
                    }}
                    onBlur={() => {
                      setShowMenu(false);
                    }}
                  >
                    <TopicsWeCoverMenuWrapper>
                      <SemiBoldTitle>{MENU_LABELS.INDIVIDUALS}</SemiBoldTitle>{" "}
                      {/* <MenuDownArrowWrapper> */}
                      <Image
                        src="home/chevron-down-black-ic.png"
                        style={{
                          width: "24px",
                          height: "24px",
                        }}
                        imgStyle={{
                          objectFit: "contain",
                        }}
                      />
                      {/* </MenuDownArrowWrapper> */}
                    </TopicsWeCoverMenuWrapper>
                    {/* <MenuDownArrowWrapper>
                  <Image
                    src="home/chevron-down-black-ic.png"
                    style={{ display: "inline" }}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                </MenuDownArrowWrapper> */}
                    {showMenu && (
                      <Menu
                        menuData={HOME_PAGE_MENU}
                        onClick={() => {}}
                        style={{
                          top: "40px",
                          left: "5px",
                          zIndex: 100,
                          width: "340px",
                        }}
                      />
                    )}
                  </Nav.Link>
                </NavLinkMenuWrapper>

                <Nav.Link
                  // href={`/about-us/${paramString}`}
                  href={encodeUrl(PAGE_PATH.ABOUT_US)}
                  onClick={() =>
                    gtmDataLayerPush("aboutus-link-click", "header")
                  }
                >
                  <SemiBoldTitle>About us</SemiBoldTitle>
                </Nav.Link>
                {/* </NavLinkDesktopOnlyWrapper> */}
                {/* <Nav.Link
              href={encodeUrl(`/premium/`)}
              onClick={() => gtmDataLayerPush("premium-link-click", "header")}
            >
              Premium
            </Nav.Link>
            <Nav.Link
              href={`${UPRISE_APP_SIGNIN_URL}${appendSlash(
                UPRISE_APP_SIGNIN_URL
              )}${paramString}`}
              target="_blank"
              className="resource-nav-link"
              onClick={() => gtmDataLayerPush("appSignin-link-click", "header")}
            >
              Log in
            </Nav.Link> */}
                {/* <Nav.Link
              href="https://resources.uprise.us"
              className="resource-nav-link"
              onClick={() => gtmDataLayerPush("resource-link-click", "header")}
            >
              Resources
            </Nav.Link> */}
              </Nav>
            </Navbar>
          </NavLinkDesktopOnlyWrapper>
        )}
        {hideMenus === false && (
          <NavLinkWrapper>
            <Navbar id="basic-navbar-nav" style={{ gap: "16px" }}>
              <CtaButton
                onClick={(evt) => {
                  navigateTo(getAppUrlByModule(OTHER_APP_PAGES.LOGIN));
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                style={{
                  minHeight: "40px",
                  cursor: "pointer",
                  padding: "8px 12px",
                  fontSize: "16px",
                }}
                ctaType="secondary"
              >
                Log in
              </CtaButton>
              <CtaButton
                onClick={(evt) => {
                  navigateTo(getAppUrlByModule(OTHER_APP_PAGES.SIGNUP));
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                style={{
                  minHeight: "40px",
                  cursor: "pointer",
                  padding: "8px 12px",
                  fontSize: "16px",
                }}
                ctaType="primary"
              >
                Get started
              </CtaButton>
            </Navbar>
          </NavLinkWrapper>
        )}
      </NavBarRootWrapper>
      {toggle && (
        <NavLinkMobileOnlyWrapper>
          <Navbar id="basic-navbar-nav">
            <Nav className="flex-column w-100">
              <NavLinkMenuWrapper>
                <Nav.Link
                  className="p-0 w-100"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <TopicsWeCoverMenuWrapper showMenu={showMenu}>
                    <HeaderText level={HEADER_LEVEL.LEVEL_4}>
                      <SemiBoldTitle>{MENU_LABELS.INDIVIDUALS}</SemiBoldTitle>
                    </HeaderText>
                    <Image
                      src="home/chevron-down-black-ic.png"
                      className="chevron-icon"
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                      imgStyle={{
                        objectFit: "contain",
                      }}
                    />
                  </TopicsWeCoverMenuWrapper>
                  {showMenu &&
                    HOME_PAGE_MENU.map((menuItem, indx) => {
                      const {
                        icon,
                        label,
                        labelHelperText,
                        navigationLink = PAGE_PATH.HOME,
                      } = menuItem ?? {};
                      const {
                        src: iconPath,
                        width: iconWidth,
                        height: iconHeight,
                      } = icon ?? {};

                      return (
                        <MenuItem
                          key={`${indx}-men-itm`}
                          onClick={(evt) => {
                            navigateTo(navigationLink);
                            evt.preventDefault();
                            evt.stopPropagation();
                          }}
                        >
                          <MenuIconWrapper>
                            <Image
                              src={iconPath}
                              style={{
                                width: `${iconWidth}`,
                                height: `${iconHeight}`,
                              }}
                              imgStyle={{
                                objectFit: "contain",
                              }}
                            />
                          </MenuIconWrapper>
                          <MenuLabel>
                            <HeaderText level={HEADER_LEVEL.LEVEL_6}>
                              <SemiBoldTitle>{label}</SemiBoldTitle>
                            </HeaderText>
                            {isNonEmpty(labelHelperText) && (
                              <BodyText type={BODY_TEXT_TYPE.BODY_COPY_SMALL}>
                                {labelHelperText}
                              </BodyText>
                            )}
                          </MenuLabel>
                        </MenuItem>
                      );
                    })}
                </Nav.Link>
              </NavLinkMenuWrapper>
              <Nav.Link
                className="partnership"
                href={encodeUrl(PAGE_PATH.PARTNER)}
                onClick={() =>
                  gtmDataLayerPush("partnership-link-click", "header")
                }
              >
                <HeaderText level={HEADER_LEVEL.LEVEL_4}>
                  <SemiBoldTitle>{MENU_LABELS.PARTNERSHIPS}</SemiBoldTitle>
                </HeaderText>
              </Nav.Link>
              <Nav.Link
                className="about-us"
                href={encodeUrl(PAGE_PATH.ABOUT_US)}
                onClick={() => gtmDataLayerPush("aboutus-link-click", "header")}
              >
                <HeaderText level={HEADER_LEVEL.LEVEL_4}>
                  <SemiBoldTitle>{MENU_LABELS.ABOUT_US}</SemiBoldTitle>
                </HeaderText>
              </Nav.Link>
              <MobileNavLinkWrapper>
                <CtaButton
                  onClick={(evt) => {
                    navigateTo(getAppUrlByModule(OTHER_APP_PAGES.LOGIN));
                    evt.preventDefault();
                    evt.stopPropagation();
                  }}
                  style={{
                    minHeight: "40px",
                    cursor: "pointer",
                    padding: "8px 12px",
                    fontSize: "16px",
                  }}
                  ctaType="secondary"
                  isStretchToFullWidth={true}
                >
                  <SemiBoldTitle>Log in</SemiBoldTitle>
                </CtaButton>
                <CtaButton
                  onClick={(evt) => {
                    navigateTo(getAppUrlByModule(OTHER_APP_PAGES.SIGNUP));
                    evt.preventDefault();
                    evt.stopPropagation();
                  }}
                  style={{
                    minHeight: "40px",
                    cursor: "pointer",
                    padding: "8px 12px",
                    fontSize: "16px",
                  }}
                  ctaType="primary"
                  isStretchToFullWidth={true}
                >
                  <SemiBoldTitle>Get started</SemiBoldTitle>
                </CtaButton>
              </MobileNavLinkWrapper>
            </Nav>
          </Navbar>
        </NavLinkMobileOnlyWrapper>
      )}
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.div`
  border-bottom: 1px solid #d1d3d4;
  position: relative;
`;

const NavBarRootWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 80px;
  height: 72px;

  .HeaderDesktopLogo {
    width: 121px;
    height: 31px;
    @media (max-width: ${Breakpoints.sm}) {
      display: none;
    }
  }
  .HeaderMobileLogo {
    width: 40px;
    height: 40px;
    @media only screen and (min-width: ${Breakpoints.sm}) {
      display: none;
    }
  }
  @media (max-width: ${Breakpoints.sm}) {
    margin: 0px 24px;
  }

  .nav-link {
    font-weight: 600 !important;
    border-bottom: 2px solid transparent;
    font-size: 16px;
    style: normal;

    &:hover {
      border-bottom: 2px solid #317af9;
    }
  }

  .community-nav-link {
    @media (max-width: ${Breakpoints.sm}) {
      display: none;
    }
  }
`;

const TopicsWeCoverMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  @media (max-width: ${Breakpoints.md}) {
    padding: 0 ${Spacing.md} !important;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease-in;
    .chevron-icon {
      transition: all 0.2s ease-in;
      transform: ${(props) =>
        props.showMenu ? "rotate(180deg)" : "rotate(360deg)"};
    }
  }
`;

const NavLinkMenuWrapper = styled.div``;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const NavLinkDesktopOnlyWrapper = styled.div`
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`;

const NavLinkMobileOnlyWrapper = styled.div`
  position: absolute;
  margin-top: 1px;
  background: ${Colors.white};
  z-index: 5;
  padding: ${Spacing.sm};
  width: 100%;
  display: none;
  .about-us {
    margin: ${Spacing.xs} ${Spacing.md} ${Spacing.md};
    padding: ${Spacing.md} 0 !important;
    border-top: 1px solid #d1d3d4;
    border-bottom: 1px solid #d1d3d4;
  }
  .partnership {
    margin: ${Spacing.md} ${Spacing.md} 0;
    padding: ${Spacing.md} 0 !important;
    border-top: 1px solid #d1d3d4;
  }
  @media (max-width: ${Breakpoints.md}) {
    display: block;
  }
`;

const NavLinkWrapper = styled.div`
  display: flex;
  @media (max-width: ${Breakpoints.md}) {
    display: none;
  }
`;

const MobileNavLinkWrapper = styled.div`
  display: flex;
  gap: ${Spacing.sm};
  justify-content: center;
  padding: ${Spacing.md};
`;

const MobileMenu = styled.div`
  display: none;
  cursor: pointer;
  .menuBarImage {
    width: 40px;
    height: 40px;
  }
  .closeImage {
    width: 35px;
    height: 35px;
  }

  @media (max-width: ${Breakpoints.md}) {
    display: block;
  }
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  gap: ${Spacing.md};

  :hover {
    background-color: ${Colors.neutralMedLight};
  }
`;

const MenuIconWrapper = styled.div``;

const MenuLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
