import React from "react";
import styled from "styled-components";
import { Breakpoints, Colors, FontSizes } from "../../styles/styleConstants";
import { HEADER_LEVEL } from "../../utils/commonConst";
import HeaderText from "./HeaderText";
import SemiBoldTitle from "./SemiBoldTitle";

export const CTA_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

function CtaButton(props) {
  const {
    children,
    color = Colors.almostBlack,
    background,
    borderColor,
    name,
    onClick,
    disabled,
    style,
    hoverBackground,
    hoverBorderColor,
    ctaType = CTA_TYPE.PRIMARY,
    isStretchToFullWidth = false,
  } = props ?? {};
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      borderColor={borderColor}
      background={background}
      hoverBackground={hoverBackground}
      hoverBorderColor={hoverBorderColor}
      color={color}
      ctaType={ctaType}
      isStretchToFullWidth={isStretchToFullWidth}
      style={style}
    >
      <HeaderText level={HEADER_LEVEL.LEVEL_6}>
        <SemiBoldTitle color={color}>
          {children}
          {name}
        </SemiBoldTitle>
      </HeaderText>
    </Button>
  );
}

export default CtaButton;

const Button = styled.button`
  ${(props) => props.style && props.style};
  background: ${(props) =>
    props.disabled
      ? Colors.neutralMedium
      : props.background
      ? props.background
      : props.ctaType === CTA_TYPE.PRIMARY
      ? Colors.teal
      : Colors.white};
  color: ${(props) =>
    props.disabled
      ? Colors.neutralDark
      : props.color
      ? props.color
      : Colors.almostBlack};
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 13px 24px;
  border-radius: 4px;
  border: 2px solid transparent;
  border-color: ${(props) =>
    props.borderColor
      ? props.borderColor
      : props.ctaType === CTA_TYPE.PRIMARY
      ? Colors.transparent
      : Colors.neutralMedium};

  min-height: 56px;
  ${(props) =>
    props?.style?.height && `height: ${props.style.height}; min-height: 0px;`}
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.isStretchToFullWidth && "width: 100%;"}

  ${(props) =>
    props.disabled === false &&
    `
  cursor: pointer;
  :hover {
    background: ${(props) =>
      props?.hoverBackground
        ? props.hoverBackground
        : props.ctaType === CTA_TYPE.PRIMARY
        ? Colors.cyanShade
        : Colors.white};
    border-color: ${(props) =>
      props?.hoverBorderColor
        ? props.hoverBorderColor
        : props.ctaType === CTA_TYPE.SECONDARY
        ? Colors.almostBlack
        : Colors.transparent};
  }
  `}
`;
