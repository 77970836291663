import axios from "axios";
import {
  API_URLS,
  EMPTY_STRING,
  ERROR_RESPONSE,
  TARGET_SERVICE,
  USER_MESSAGE,
} from "./commonConst";
import { genUpriseSignupRequestId, isEmpty, isNonEmpty } from "./commonUtil";

const axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST",
  },
};

export const API_SYSTEM_TYPE = {
  BACKEND_GENERIC: "BACKEND_GENERIC",
  BACKEND_AI_API: "BACKEND_AI_API",
};

const API_KEY_NAME = {
  MARKETING_REQ_API_KEY: "MARKETING_REQ_API_KEY",
  BACKEND_AI_API_KEY: "BACKEND_AI_API_KEY",
};
export const submitEarlyAccess = async (submittedData) => {
  const url = `${process.env.GATSBY_UPRISE_SERVICE_BASE_URL}/users/submit-waitlist`;
  return postAPICall(url, submittedData, API_SYSTEM_TYPE.BACKEND_GENERIC);
};

export const getWaitlistDetail = async ({ id }, payload) => {
  const url = `${process.env.GATSBY_UPRISE_SERVICE_BASE_URL}/users/waitlist/${id}`;
  return putAPICall(url, payload, API_SYSTEM_TYPE.BACKEND_GENERIC);
};

export const submitReferralSignup = async (signUpPayload) => {
  const { fullName, email, password, mobilePhoneNumber, referralCode } =
    signUpPayload ?? {};

  if (
    isNonEmpty(fullName) &&
    isNonEmpty(email) &&
    isNonEmpty(password) &&
    isNonEmpty(referralCode)
  ) {
    const url = `${process.env.GATSBY_UPRISE_SERVICE_BASE_URL}/auth/ref/signup`;
    return postAPICall(
      url,
      {
        ...signUpPayload,
        firstName: fullName,
      },
      API_SYSTEM_TYPE.BACKEND_GENERIC
    );
  }
  return Promise.reject("Insufficient data");
};

export const submitEquityAIQuestion = async (equityQuestionPayload) => {
  const { question, income, saving, stockOption, email } =
    equityQuestionPayload ?? {};
  if (isNonEmpty(question) && isNonEmpty(email)) {
    const url = `${process.env.GATSBY_UPRISE_ADMIN_SERVICE_BASE_URL}/ai/mrkt/qa`;
    return postAPICall(
      url,
      {
        ...equityQuestionPayload,
      },
      API_SYSTEM_TYPE.BACKEND_AI_API
    );
  }
  return Promise.reject("Insufficient data");
};

// const manageSessionStorage = (itemName, itemValue) => {
//   console.log("itemName:", itemName);
//   console.log("itemValue:", itemValue);
//   let apiKey = getSessionStorageItem(itemName);
//   if (isEmpty(apiKey)) {
//     setSessionStorageItem(itemName, itemValue);
//     apiKey = getSessionStorageItem(itemName);
//   }
//   return apiKey;
// };

const getApiKey = (apiSystemType) => {
  switch (apiSystemType) {
    case API_SYSTEM_TYPE.BACKEND_GENERIC:
      // return manageSessionStorage(
      //   API_KEY_NAME.MARKETING_REQ_API_KEY,
      //   process.env.GATSBY_MARKETING_REQ_API_KEY
      // );
      return process.env.GATSBY_MARKETING_REQ_API_KEY;
    case API_SYSTEM_TYPE.BACKEND_AI_API:
      // return manageSessionStorage(
      //   API_KEY_NAME.BACKEND_AI_API_KEY,
      //   process.env.GATSBY_MARKETING_REQ_API_KEY
      // );
      return process.env.GATSBY_BACKEND_AI_API_KEY;
    default:
      return null;
  }
};

const postAPICall = async (url, data, apiSystemType) => {
  axios.defaults.headers.common = { "X-API-KEY": getApiKey(apiSystemType) };
  return await axios.post(url, data).catch((error) => {
    throw error;
  });
};

const putAPICall = async (url, data, apiSystemType) => {
  axios.defaults.headers.common = { "X-API-KEY": getApiKey(apiSystemType) };
  return await axios.put(url, data).catch((error) => {
    throw error;
  });
};

const getAPICall = async (url, apiSystemType) => {
  axios.defaults.headers.common = { "X-API-KEY": getApiKey(apiSystemType) };
  return await axios.get(url).catch((error) => {});
};

export function isString(input) {
  return typeof input === "string";
}

export function isObject(input) {
  return input instanceof Object;
}

export const decodeAPIException = (exception) => {
  if (isEmpty(exception)) {
    return null;
  }
  const { response } = exception ?? {};
  const { status, data } = response ?? {};
  const { message } = data ?? {};
  if (isNonEmpty(message)) {
    if (message === ERROR_RESPONSE.REQUEST_FAILED_WITH_STATUS_400) {
      return USER_MESSAGE.SYSTEM_ERROR;
    }
    return message;
  }
  return USER_MESSAGE.SYSTEM_ERROR;
};

export const getUserMessage = (apiResponseMessage, email) => {
  if (apiResponseMessage == null || apiResponseMessage === EMPTY_STRING) {
    return EMPTY_STRING;
  }
  if (
    isObject(apiResponseMessage) &&
    apiResponseMessage?.response?.status === 400
  ) {
    const errorObj = apiResponseMessage?.response?.data;
    const { errorCode, message } = errorObj ?? {};
    if (
      isString(message) &&
      message !== "" &&
      (message.includes(ERROR_RESPONSE.ALREADY_SUBSCRIBED) ||
        message.includes(ERROR_RESPONSE.TOO_MANY_SIGNUP_REQUEST) ||
        errorCode === "EMAIL_FOUND_IN_SYSTEM")
    ) {
      return `${email} ${USER_MESSAGE.ALREADY_SUBSCRIBED}`;
    }
  } else if (
    isObject(apiResponseMessage) &&
    apiResponseMessage?.response?.status === 404
  ) {
    const message = apiResponseMessage?.response?.statusText;
    if (
      isString(message) &&
      message !== "" &&
      message.includes(ERROR_RESPONSE.NOT_FOUND)
    ) {
      return ERROR_RESPONSE.NOT_FOUND;
    }
  } else if (
    isObject(apiResponseMessage) &&
    apiResponseMessage?.message === ERROR_RESPONSE.NETWORK_ERROR
  ) {
    return ERROR_RESPONSE.NETWORK_ERROR;
  }
  return EMPTY_STRING;
};

export const isErrorInUserMessage = (userMessage) => {
  if (userMessage == null || userMessage === EMPTY_STRING) {
    return false;
  }
  if (
    userMessage.includes(USER_MESSAGE.ALREADY_SUBSCRIBED) ||
    userMessage.includes(USER_MESSAGE.NETWORK_ERROR) ||
    userMessage.includes(ERROR_RESPONSE.NOT_FOUND)
  ) {
    return true;
  }
  return false;
};

export const isRequestSuccess = (message, target) => {
  switch (target) {
    case TARGET_SERVICE.MAIL_CHIMP: {
      const { result, msg } = message ?? {};
      if (result === "success") {
        return true;
      }
      return false;
    }

    case TARGET_SERVICE.UPRISE: {
      const { status } = message ?? {};
      if (status === 200) {
        return true;
      }
      return false;
    }
  }
  return false;
};

export const logGetStartedEvent = async (eventPayload) => {
  return postAPICall(
    `${process.env.GATSBY_UPRISE_SERVICE_BASE_URL}${API_URLS.EVENT_LOG}`,
    {
      eventCategory: "marketing_site",
      eventType: "ui_action",
      eventCode: "get_started",
      eventPayload,
    },
    API_SYSTEM_TYPE.BACKEND_GENERIC
  );
};
