import React from "react";
import styled from "styled-components";
import { FaYoutube, FaTiktok } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Colors } from "../../styles/styleConstants";
import { SocialLinks } from "../../data/commonData";

export default function SocialMediaIcons({
  color = Colors.black,
  size = "2rem",
}) {
  return (
    <SocialMediaIconsWrapper>
      <a href={SocialLinks.youtube} target="_blank" rel="noopener noreferrer">
        <IconContext.Provider
          value={{ color: color, size: size, title: "YouTube" }}
        >
          <FaYoutube />
        </IconContext.Provider>
      </a>
      <a href={SocialLinks.tiktok} target="_blank" rel="noopener noreferrer">
        <IconContext.Provider
          value={{ color: color, size: size, title: "TikTok" }}
        >
          <FaTiktok />
        </IconContext.Provider>
      </a>
    </SocialMediaIconsWrapper>
  );
}

export const SocialMediaIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  a:hover > * {
    color: ${Colors.primary} !important;
  }
`;
