import { composeReqParam } from "./commonUtil";

export const FIVE_SECONDS = 5000;
const SIGNUP_CODE_PARAM_VALUE = "signupCode";
export const QUERY_PARAMS = {
  REF: "ref",
  SIGNUP_CODE: "signupCode",
};
export const APP_QUERY_PARAMS = {
  MODULE: "uMod",
  REF_ID: "uRefId",
  SIGNUP_CODE: "uSignupCode",
  SOURCE: "uSource",
};

export const ERROR_RESPONSE = {
  ALREADY_SUBSCRIBED: "already subscribed",
  TOO_MANY_SIGNUP_REQUEST: "has too many recent signup requests",
  NETWORK_ERROR: "Network Error",
  NOT_FOUND: "Not Found",
  REQUEST_FAILED_WITH_STATUS_400: "Request failed with status code 400",
  SYSTEM_ERROR_TRY_LATER: "System error, Please try again later",
};

export const USER_MESSAGE = {
  ALREADY_SUBSCRIBED: "is already subscribed to list Uprise.",
  NETWORK_ERROR: "Network Error",
  SYSTEM_ERROR: "System error, Please try again",
};

export const EMPTY_STRING = "";

export const TARGET_SERVICE = {
  MAIL_CHIMP: "MailChimp",
  UPRISE: "Uprise",
};

export const USER_MESSAGE_TYPE = {
  ERROR: "error",
  CONFIRMATION: "confirmation",
  WARNING: "warning",
};

export const PRIVACY_URL =
  "https://uprise-terms-policies.s3.us-east-2.amazonaws.com/uprise-privacy-policy.pdf";
export const TERMS_URL =
  "https://uprise-terms-policies.s3.us-east-2.amazonaws.com/uprise-terms-of-service.pdf";
export const FORM_ADV =
  "https://uprise-terms-policies.s3.us-east-2.amazonaws.com/ADV+-+Uprise+Advisers+LLC.pdf";
export const NERDWALLET_TERMS_URL = "https://www.nerdwallet.com/p/terms-of-use";
export const NERDWALLET_PRIVACY_URL =
  "https://www.nerdwallet.com/p/privacy-policy";
export const TRUSTPILOT_URL = "https://www.trustpilot.com/review/uprise.us";
export const PRODUCTHUNT_URL =
  "https://www.producthunt.com/products/uprise-2/reviews";
const JESSICA_CALENDLY = "https://calendly.com/d/ck28-8wv-b6m";
const CHRIS_CALENDLY = "https://calendly.com/chris-goodmacher/uprise-intro";
export const CALENDARLY_LINK = JESSICA_CALENDLY;
export const RIPPLING_CALENDLY_LINK = "https://calendly.com/d/cpz3-3nf-s45";
export const STILL_QUESTIONS_NERDWALLET_CALENDLY_LINK =
  "https://calendly.com/chris-goodmacher/nerdwallet-coach-powered-by-uprise-intro-call";
export const STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK =
  "https://calendly.com/d/cpzf-jfx-yt7";

export const UPRISE_APP_PREMIUM_PAGE_URL =
  process.env.GATSBY_UPRISE_APP_PREMIUM_PAGE_URL;
export const NERDWALLET_APP_HOST = process.env.GATSBY_NERDWALLET_APP_HOST;
export const NERDWALLET_SMB_APP_HOST =
  process.env.GATSBY_NERDWALLET_SMB_APP_HOST;

export const NERDWALLET_MARKETING_HOST_NAME =
  process.env.GATSBY_NERDWALLET_MARKETING_HOST_NAME;
export const NERDWALLET_SMB_MARKETING_HOST_NAME =
  process.env.GATSBY_NERDWALLET_SMB_MARKETING_HOST_NAME;
export const UPRISE_APP_SUB_DOMAIN = process.env.GATSBY_UPRISE_APP_SUB_DOMAIN;
export const UPRISE_APP_HOST = process.env.GATSBY_UPRISE_APP_HOST;
export const UPRISE_APP_SIGNUP_URL = `${UPRISE_APP_HOST}/signup`;
export const UPRISE_APP_SIGNIN_URL = `${UPRISE_APP_HOST}/login`;

export const ENTER_KEY_CODE = 13;

export const REQUIRED_MESSAGE_PREFIX = "Please enter your";
export const VALIDATION_MESSAGE_VALID_PHONE_NUMBER =
  "Please enter a valid phone number";

export const LINK_SIZE = {
  SMALL: "small",
  LARGE: "large",
};

export const DEFAULT_REFREE_NAME = "Uprise";
export const DEFAULT_WAITLIST_COUNT = 500;
export const HEADER_LEVEL = {
  LEVEL_1: "1",
  LEVEL_1_1: "1_1",
  LEVEL_2: "2",
  LEVEL_3: "3",
  LEVEL_4: "4",
  LEVEL_4_1: "4_1",
  LEVEL_5: "5", // paragraph title.
  LEVEL_6: "6", // paragraph text.
  LEVEL_6_1: "6_1", //nerdwallet header buttons
  LEVEL_7: "7", //
  BODY_COPY: "Body copy",
};

export const API_URLS = {
  EVENT_LOG: "/event/mktng/ulog",
};

export const BODY_TEXT_TYPE = {
  BODY_COPY_SMALL: "body_copy_small",
  BODY_COPY_NORMAL: "body_copy_normal",
  BODY_COPY_TITLE: "body_copy_title",
  BODY_COPY_LARGE: "body_copy_large",
  BODY_TEXT_SMALL: "body_text_small",
};

export const BODY_LINK_TYPE = {
  BODY_LINK_NORMAL: "body_link_normal",
  BODY_LINK_SMALL: "body_link_small",
  BODY_LINK_SMALLEST: "body_link_smallest",
};

export const QUESTION_MARK = "?";
export const AMPERSAND = "&";
export const CLAIR_SIGNUP_CODE = "clair";
export const SANA_SIGNUP_CODE = "sana";
export const PEACH_SIGNUP_CODE = "peach";
export const PULL_SIGNUP_CODE = "pull";

export const MODULE_NAME = {
  MONEY_ALLOCATION: "money-allocation",
  RETIREMENT: "retirement",
  ALL_ACCESS: "all-access",
  PORTFOLIO_ADVICE: "portfolio-advice",
  RSU: "rsu",
  COMPANY_EQUITY: "company-equity",
  CORE: "core",
};

const ROOT = "/";

export const PAGE_NAME = {
  PARTNER: "partner",
};
export const PAGE_PATH = {
  ABOUT_US: "/about-us",
  HOME: ROOT,
  [MODULE_NAME.MONEY_ALLOCATION]: "/money-allocation",
  [MODULE_NAME.RETIREMENT]: "/retirement",
  [MODULE_NAME.ALL_ACCESS]: "/all-access",
  [MODULE_NAME.PORTFOLIO_ADVICE]: "/portfolio-advice",
  [MODULE_NAME.RSU]: "/rsu",
  [MODULE_NAME.COMPANY_EQUITY]: "/company-equity",
  [MODULE_NAME.CORE]: "/core",
  CLAIR: "/clair",
  SANA: "/sana",
  PREMIUM: "/premium",
  TAX_CALCULATOR: "/tax-calculator",
  PARTNER: `/${PAGE_NAME.PARTNER}`,
  AI_EQUITY: "/ai/equity",
  PEACH: "/peach",
  PULL: "/pull",
  HOW_IT_WORKS: "/how-it-works",
};
export const OTHER_APP_PAGES = {
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  HOME: "HOME",
  TAX_CALCULATOR: "TAX_CALCULATOR",
};
export const APP_PAGE_URL_BY_MODULE = {
  [MODULE_NAME.MONEY_ALLOCATION]: `/signup?${composeReqParam(
    APP_QUERY_PARAMS.MODULE,
    MODULE_NAME.MONEY_ALLOCATION
  )}`,
  [MODULE_NAME.RETIREMENT]: `/signup?${composeReqParam(
    APP_QUERY_PARAMS.MODULE,
    MODULE_NAME.RETIREMENT
  )}`,
  [MODULE_NAME.ALL_ACCESS]: `/signup?${composeReqParam(
    APP_QUERY_PARAMS.MODULE,
    MODULE_NAME.ALL_ACCESS
  )}`,
  [MODULE_NAME.PORTFOLIO_ADVICE]: `/signup?${composeReqParam(
    APP_QUERY_PARAMS.MODULE,
    MODULE_NAME.PORTFOLIO_ADVICE
  )}`,
  [MODULE_NAME.RSU]: `/signup?${composeReqParam(
    APP_QUERY_PARAMS.MODULE,
    MODULE_NAME.RSU
  )}`,
  [MODULE_NAME.COMPANY_EQUITY]: `/signup?${composeReqParam(
    APP_QUERY_PARAMS.MODULE,
    MODULE_NAME.COMPANY_EQUITY
  )}`,
  [MODULE_NAME.CORE]: `/signup?${composeReqParam(
    APP_QUERY_PARAMS.MODULE,
    MODULE_NAME.CORE
  )}`,
  [OTHER_APP_PAGES.SIGNUP]: `/signup`,
  [OTHER_APP_PAGES.LOGIN]: `/login`,
  [OTHER_APP_PAGES.HOME]: `/`,
  [OTHER_APP_PAGES.TAX_CALCULATOR]: "/tax-calculator",
};

export const PARTNERS = {
  ANGEL: "angel",
  PULL: "pull",
  PEACH: "peach",
};

export const PRICING_TITLE = "Pricing";

export const INPUT_NUMBER_TYPE = {
  CURRENCY: "currency",
  PERCENTAGE: "percentage",
  REGULAR: "regular",
};

export const AFFILIATE_DISCOUNT_PERCENTAGE = {
  [PARTNERS.PEACH]: 15,
  [PARTNERS.PULL]: 15,
};

export const AFFILIATE_DISCOUNT_CODE = {
  [PARTNERS.PEACH]: "PEACH15",
  [PARTNERS.PULL]: "PULL15",
};

export const NERDWALLET_SIGNUP = NERDWALLET_APP_HOST + "/signup";
export const NERDWALLET_LOGIN = NERDWALLET_APP_HOST + "/login";

export const NERDWALLET_SMB_SIGNUP = NERDWALLET_SMB_APP_HOST + "/signup";
export const NERDWALLET_SMB_LOGIN = NERDWALLET_SMB_APP_HOST + "/login";
