import { navigate } from "gatsby";
import { StringParam, useQueryParam } from "use-query-params";
import { isString, logGetStartedEvent } from "./apiUtils";
import {
  AMPERSAND,
  APP_PAGE_URL_BY_MODULE,
  APP_QUERY_PARAMS,
  CLAIR_SIGNUP_CODE,
  MODULE_NAME,
  OTHER_APP_PAGES,
  PAGE_PATH,
  PEACH_SIGNUP_CODE,
  PULL_SIGNUP_CODE,
  QUERY_PARAMS,
  QUESTION_MARK,
  SANA_SIGNUP_CODE,
  UPRISE_APP_HOST,
  UPRISE_APP_SUB_DOMAIN,
} from "./commonConst";

const SIGNUP_REQUEST_ID = "signup_request_id";
const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

// /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/im;

export function getPositionText(currentPosition) {
  return currentPosition != null
    ? `You're ${currentPosition.toLocaleString("en-US")} in line `
    : "";
}

export function getTotalWaitlistText(totalCount) {
  return totalCount != null
    ? `You’re one of ${totalCount.toLocaleString(
        "en-US"
      )} people who joined our waitlist.`
    : "";
}

export function isJsonObject(input) {
  return input != null && input.constructor === {}.constructor;
}

export function isEmpty(input) {
  if (input == null || input === "") {
    return true;
  }
  if (input instanceof Map) {
    return input.size === 0;
  }
  if (input instanceof Array) {
    return input.length === 0;
  }
  // Object should be checked at the end.
  // Don't move instance check for Object
  if (isJsonObject(input)) {
    return Object.keys(input).length === 0;
  }
  return false;
}

export function isNonEmpty(input) {
  return !isEmpty(input);
}

export function isMap(input) {
  return input instanceof Map;
}

// reqParamType : QUERY_PARAMS
export function parseReqParams(reqParamType) {
  let paramValue = useQueryParam(reqParamType, StringParam)[0];
  try {
    if (paramValue == null && typeof window !== "undefined") {
      const searchString = window.location.search;
      const updatedSearchString = searchString.replace(
        `?${reqParamType}%3D`,
        `?${reqParamType}=`
      );
      const urlParams = new URLSearchParams(updatedSearchString);
      paramValue = urlParams.get(reqParamType);
    }
  } catch (exception) {
    console.log("exception:", exception);
    // TODO add logging
  }

  return paramValue;
}

export function isUpriseAppUrl(url) {
  return (url ?? "").includes(UPRISE_APP_SUB_DOMAIN);
}

export function isExternalLink(url) {
  return (url ?? "").includes("https") || (url ?? "").includes("http");
}

export function genUpriseSignupRequestId() {
  let signupRequestId = localStorage.getItem(SIGNUP_REQUEST_ID);
  if (isEmpty(signupRequestId)) {
    signupRequestId = (+new Date() + Math.random() * 100).toString(32);
    localStorage.setItem(SIGNUP_REQUEST_ID, signupRequestId);
  }
  return signupRequestId;
}

// target: _blank, _self, _parent, _top
export function openWebLink(link, target) {
  if (isNonEmpty(link)) {
    window.open(link, target ?? "_blank");
  }
}

// target: _blank, _self, _parent, _top
export function encodeUrl(url, customReqParam) {
  if (isNonEmpty(url) && isString(url) && typeof window !== "undefined") {
    const rawWindowSearchStr = window.location.search;
    let derivedUrl = url;
    let questionMarkIndex;
    if (isNonEmpty(customReqParam)) {
      questionMarkIndex = derivedUrl.indexOf(QUESTION_MARK);
      derivedUrl = `${derivedUrl}${
        questionMarkIndex === -1 ? QUESTION_MARK : AMPERSAND
      }${customReqParam}`;
    }
    if (isNonEmpty(rawWindowSearchStr)) {
      questionMarkIndex = derivedUrl.indexOf(QUESTION_MARK);
      derivedUrl = `${derivedUrl}${
        questionMarkIndex === -1 ? QUESTION_MARK : AMPERSAND
      }${rawWindowSearchStr.substring(1)}`;
    }
    return derivedUrl;
  }
  return url;
}

export function appendSlash(url) {
  return (url ?? "").slice(-1) === "/" ? url : url + "/";
}

export function getAppUrlByModule(moduleName) {
  return `${UPRISE_APP_HOST}${
    APP_PAGE_URL_BY_MODULE[moduleName] ??
    APP_PAGE_URL_BY_MODULE[OTHER_APP_PAGES.SIGNUP]
  }`;
}

export function composeReqParam(name, value) {
  return isNonEmpty(name) && isNonEmpty(value) ? `${name}=${value}` : "";
}

export function getSignupCodeParamStr(signupCode) {
  return composeReqParam(QUERY_PARAMS.SIGNUP_CODE, signupCode);
}

export function appendReqParam(param1Str, param2Str) {
  return `${param1Str ?? ""}${
    isNonEmpty(param1Str) && isNonEmpty(param2Str)
      ? `${AMPERSAND}${param2Str}`
      : param2Str
  } `;
}

export function getModuleUrl(moduleName) {
  return encodeUrl(PAGE_PATH[moduleName] ?? PAGE_PATH.HOME);
}

export function navigateToApp(url, appReqParamStr) {
  const signupReqId = genUpriseSignupRequestId();
  logGetStartedEvent({ signupReqId, appReqParam: appReqParamStr });
  const targetUrl = isEmpty(url)
    ? process.env.GATSBY_UPRISE_APP_SIGNUP_URL
    : url;
  let derivedReqParams = appReqParamStr;
  const rawWindowSearchStr = window.location.pathname ?? "";
  if (rawWindowSearchStr?.includes(PAGE_PATH.CLAIR)) {
    derivedReqParams = appendReqParam(
      appReqParamStr,
      composeReqParam(APP_QUERY_PARAMS.SIGNUP_CODE, CLAIR_SIGNUP_CODE)
    );
  } else if (rawWindowSearchStr?.includes(PAGE_PATH.SANA)) {
    derivedReqParams = appendReqParam(
      appReqParamStr,
      composeReqParam(APP_QUERY_PARAMS.SIGNUP_CODE, SANA_SIGNUP_CODE)
    );
  } else if (rawWindowSearchStr?.includes(PAGE_PATH.PEACH)) {
    derivedReqParams = appendReqParam(
      appReqParamStr,
      composeReqParam(APP_QUERY_PARAMS.SIGNUP_CODE, PEACH_SIGNUP_CODE)
    );
  } else if (rawWindowSearchStr?.includes(PAGE_PATH.PULL)) {
    derivedReqParams = appendReqParam(
      appReqParamStr,
      composeReqParam(APP_QUERY_PARAMS.SIGNUP_CODE, PULL_SIGNUP_CODE)
    );
  }
  const refReqParam = appendReqParam(
    derivedReqParams,
    composeReqParam(APP_QUERY_PARAMS.REF_ID, signupReqId)
  );
  const fullTargetUrl = encodeUrl(targetUrl, refReqParam);
  openWebLink(fullTargetUrl);
}

export function navigateTo(path, customReqParam) {
  if (isEmpty(path)) {
    navigate(encodeUrl(PAGE_PATH[MODULE_NAME.HOME]));
    return;
  }
  if (isUpriseAppUrl(path)) {
    navigateToApp(path, customReqParam);
    return;
  } else if (isExternalLink(path)) {
    openWebLink(path);
    return;
  }
  navigate(encodeUrl(path));
}

export const scrollTo = (selector) => {
  const section = document.querySelector(selector);
  section.scrollIntoView({ behavior: "smooth", block: "center" });
};

export const scrollToPageTop = (top) => {
  if (window != null) {
    window.scrollTo({
      top: top ?? 0,
      left: 0,
      behavior: "smooth",
    });
  }
};

export const isEmailValid = (email) => {
  return emailRegExp.test(email);
};

export function initCap(input) {
  if (isString(input) && isNonEmpty(input)) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
  return input;
}

export const getHostName = () => {
  if (typeof window !== "undefined") {
    return window?.location?.hostname || document?.location?.hostname;
  }
};
