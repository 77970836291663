export const ContactEmail = "hello@uprise.us";
export const JessicaEmail = "jessica@uprise.us";

export const GTAG_KEY = "AW-431627417/PIGsCOvrqPMCEJm56M0B";

export const SEO = {
  description: "Premium, AI-powered financial advisory for your platform.",
  twitterUsername: "@nanthaupriseus1",
  title: "Premium, AI-powered financial advisory for your platform.",
  twitterImage:
    "https://uprise-asset.s3.us-east-2.amazonaws.com/uprise-partner-meta-image.png",
};

export const NERDWALLET_SEO = {
  description: "NerdWallet Coach, powered by Uprise",
  twitterUsername: "",
  title: "NerdWallet Coach, powered by Uprise",
  twitterImage: "",
};

export const SocialLinks = {
  youtube: "https://www.youtube.com/channel/UC_9O8oJREc1ldakUYn2r2OQ",
  tiktok: "https://www.tiktok.com/@joinuprise",
};

export const NERDWALLET_COACH_HELLO_EMAIL = "hello@nerdwalletcoach.uprise.us";
export const NERDWALLET_COACH_SMB_HELLO_EMAIL =
  "hello@nerdwalletcoach.smb.uprise.us";
