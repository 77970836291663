export const gtmDataLayerPush = (
  event,
  category = "misc.",
  label = "",
  value = ""
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    category: category,
    label: label,
    value: value,
  });
};

export const fbpLeadTracking = () => {
  if (typeof window !== "undefined") {
    if (window.fbq != null) {
      window.fbq("track", "Lead");
    }
  }
};
