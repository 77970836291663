/* eslint-disable no-underscore-dangle */
import { navigate } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Colors } from "../../styles/styleConstants";
import {
  BODY_TEXT_TYPE,
  HEADER_LEVEL,
  PAGE_PATH,
} from "../../utils/commonConst";
import { encodeUrl, isNonEmpty, navigateTo } from "../../utils/commonUtil";
import BodyText from "./BodyText";
import HeaderText from "./HeaderText";
import Image from "./Image";

function Menu(props) {
  const { menuData, onClick, style, className } = props;
  const _renderMenuItems = () => {
    if (isNonEmpty(menuData) && Array.isArray(menuData)) {
      return menuData.map((menuItem, indx) => {
        const {
          icon,
          label,
          labelHelperText,
          navigationLink = PAGE_PATH.HOME,
        } = menuItem ?? {};
        const {
          src: iconPath,
          width: iconWidth,
          height: iconHeight,
        } = icon ?? {};
        return (
          <MenuItem
            key={`${indx}-men-itm`}
            onClick={(evt) => {
              navigateTo(navigationLink);
              evt.preventDefault();
              evt.stopPropagation();
            }}
          >
            <MenuIconWrapper>
              <Image
                src={iconPath}
                style={{ width: `${iconWidth}`, height: `${iconHeight}` }}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </MenuIconWrapper>
            {/* <MenuLabel>{label}</MenuLabel> */}
            {/* {label} */}
            <MenuLabel>
              <HeaderText level={HEADER_LEVEL.LEVEL_6}>{label}</HeaderText>
              {isNonEmpty(labelHelperText) && (
                <BodyText type={BODY_TEXT_TYPE.BODY_COPY_SMALL}>
                  {labelHelperText}
                </BodyText>
              )}
            </MenuLabel>
          </MenuItem>
        );
      });
    }
    return null;
  };

  return (
    <MenuWrapper style={style} className={className}>
      <MenuItemWrapper>{_renderMenuItems()}</MenuItemWrapper>
    </MenuWrapper>
  );
}

export default Menu;

const MenuWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.neutralDark};
  position: absolute;
  // TODO Need to revisit positioning
  right: 50px;
  top: 45px;
  margin: 12px 12px;
  background: ${Colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 0px;
  width: 100%;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 8px 24px;
  gap: 16px;
  width: 100%;

  :hover {
    background-color: ${Colors.neutralMedLight};
  }
`;
const MenuIcon = styled.img`
  margin: 0.4rem 0.6rem;
  margin-right: 0.2rem;
  width: 1rem;
`;

const MenuIconWrapper = styled.div``;

const MenuLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
